import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchQuestions = createAsyncThunk(
  "counter/fetchQuestions",
  async (key) => {
    const response = await fetch(`/api/student-testing/questions?key=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    return data;
  }
);

export const sendAnswer = createAsyncThunk(
  "counter/sendAnswer",
  async ({ key, questionId, answer }) => {
    const response = await fetch(
      `/api/student-testing/answer?key=${key}&question_id=${questionId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ student_answer: answer }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to send answer");
    }
    const data = await response.text();
    return data;
  }
);

const initialState = [];

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  //   reducers: {
  // addAnswer: (state, action) => {
  //   const { payload } = action;
  //   console.log("text=", payload.text, "count", payload.count);
  // },

  // get10Questions: (state, action) => {
  //   const { payload } = action;
  //   console.log(payload);
  //   fetch(`/api/student-testing/questions?key=${payload}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(
  //           "Network response was not ok " + response.statusText
  //         );
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Success data:", data);
  //       state.splice(0, state.length, ...data);

  //       console.log("state", state);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // },
  //   },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        // Операції, які ви хочете виконати під час очікування запиту
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        // console.log("fetchQuestions", "state", state, "action", action);
        state.splice(0, state.length, ...action.payload);
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        // Обробка помилки, якщо запит відхилений
        console.error("Failed to fetch questions:", action.error.message);
      })
      .addCase(sendAnswer.pending, (state) => {
        // Операції, які ви хочете виконати під час очікування відправлення даних
      })
      .addCase(sendAnswer.fulfilled, (state, action) => {
        // Операції після успішного відправлення даних
        console.log("Answer sent successfully:", action.payload);
        // Тут ви можете змінити стан, якщо це потрібно
      })
      .addCase(sendAnswer.rejected, (state, action) => {
        // Обробка помилки, якщо відправлення відхилене
        console.error("Failed to send answer:", action.error);
      });
  },
});
export const { addAnswer, get10Questions } = counterSlice.actions;

export default counterSlice.reducer;
