import '../App.css';
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { fetchQuestions } from '../features/counter/counterSlice'; 
import { useNavigate } from 'react-router-dom';





export default function AnswersPage(){
    let isCountAnswer = 0
    const dispatch = useDispatch();
    const location = useLocation();
    const keyInPath = location.pathname.split('/')
    const key = keyInPath[2];
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchQuestions(key));
    }, [key, dispatch]); 
    
    const counter = useSelector((state) => state.counter);


    
    counter.forEach(item => {
        if(item.student_answer !== ''){
            isCountAnswer++
        }
    });

    const handleReload = () => {
        // window.location.reload();
        navigate('/block')
    };

    return (
        <div className='answer-page'>
            <h2>Відповіді:</h2>
            <p>Всього відповідей {isCountAnswer}/{counter.length}</p>
            {counter.map((item, index) => (
                <div key={index} className='answer'>
                    <div className='number-question'>
                        <span className='number'>{index + 1}</span>
                        <p>Запитання:  {item.question}?</p>
                    </div>
                    {/* <p className='answer-title'>Очікувана відповідь:  {item.answer}</p> */}
                    <p className='answer-title'>Відповідь користувача:  {item.student_answer}</p>

                </div>
            ))}
            <button onClick={handleReload}>ТЕСТУВАННЯ ЗАВЕРШЕНО. ВАШІ ВІДПОВІДІ ЗБЕРЕЖЕНО.</button>
        </div>
    )
}
