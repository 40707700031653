import "../App.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AnswersPage from "./AnsawersPage";
import { fetchQuestions, sendAnswer } from "../features/counter/counterSlice";

const QUESTIONS_NUM = 10;

function Testing() {
  const location = useLocation();
  const keyInPath = location.pathname.split("/");
  const key = keyInPath[2];
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.counter);

  const [countQuestion, setCountQuestion] = useState(0);
  const [isQestion, setIsQuestion] = useState("");
  const [isAnswer, setIsAnwser] = useState(false);
  const [getAnswer, setGetAnwser] = useState("");
  const [id, setId] = useState("");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    dispatch(fetchQuestions(key));
  }, [key, dispatch]);

  useEffect(() => {
    if (counter && counter.length > 0) {
      setQuestions(counter);
      setIsQuestion(counter[0].question);
      setId(counter[0].id);
    }
  }, [counter]);

  useEffect(() => {
    if (questions.length > 0 && countQuestion < questions.length) {
      setIsQuestion(questions[countQuestion].question);
      setId(questions[countQuestion].id);
    }
  }, [questions, countQuestion]);

  const handleNextQuestionClick = () => {
    setGetAnwser("");
    if (countQuestion < counter.length - 1) {
      setCountQuestion((prevCount) => prevCount + 1);
    } else {
      setIsAnwser(true);
    }
  };

  const handleSubmitClick = async () => {
    try {
      await dispatch(
        sendAnswer({
          key: key,
          questionId: questions[countQuestion].id,
          answer: getAnswer,
        })
      );
      handleNextQuestionClick(questions[countQuestion]);
    } catch (error) {
      console.error("Failed to send answer:", error);
    }
  };

  return (
    <div className="testing-wrap">
      {isAnswer ? (
        <AnswersPage />
      ) : (
        <div className="testing-main">
          <div className="container">
            <header className="header">
              <h2>
                {countQuestion + 1}/{QUESTIONS_NUM} 
              </h2>
              <h3>{isQestion}?</h3>
            </header>
            <div className="general">
              <div className="question">
                <textarea
                  type="text"
                  className="input-answer"
                  onChange={(e) => setGetAnwser(e.target.value)}
                  value={getAnswer}
                />
              </div>
            </div>
          </div>
          <button onClick={handleSubmitClick}>ВІДПРАВИТИ</button>
        </div>
      )}
    </div>
  );
}

export default Testing;
